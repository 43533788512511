/* Log Vue Router Routes */
const logs = [
  {
    path: '/logs',
    name: 'log-list',
    component: () => import('@/views/log/LogList.vue'),
    meta: {
      layout: 'content',
      resource: 'Super',
      action: 'read'
    },
  },
  {
    path: '/logs/:id',
    name: 'log-view',
    component: () => import('@/views/log/LogView.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Super',
      action: 'read'
    },
  },
]

export default logs
