/* Product Vue Router Routes */
const products = [
  {
    path: '/products',
    name: 'product-list',
    component: () => import('@/views/product/ProductList.vue'),
    meta: {
      layout: 'content',
      resource: 'Reference',
      action: 'read'
    },
  },
  {
    path: '/products/create',
    name: 'product-create',
    component: () => import('@/views/product/ProductCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'Administration',
      action: 'create'
    },
  },
  {
    path: '/products/:id',
    name: 'product-view',
    component: () => import('@/views/product/ProductView.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Reference',
      action: 'read'
    },
  },
  {
    path: '/products/edit/:id',
    name: 'product-edit',
    component: () => import('@/views/product/ProductEdit.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Administration',
      action: 'update'
    },
  },
]

export default products
