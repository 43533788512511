/* Reports Vue Router Routes */
const reports = [
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/report/Reports.vue'),
    meta: {
      layout: 'content',
      resource: 'Reports',
      action: 'read'
    },
  },
  {
    path: '/reports/:year',
    name: 'reports-year',
    component: () => import('@/views/report/Reports.vue'),
    meta: {
      layout: 'content',
      resource: 'Reports',
      action: 'read'
    },
  }
]

export default reports
