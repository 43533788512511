/* DiscountCode Vue Router Routes */
const discountcodes = [
  {
    path: '/discountcodes',
    name: 'discountcode-list',
    component: () => import('@/views/discountcode/DiscountCodeList.vue'),
    meta: {
      layout: 'content',
      resource: 'Administration',
      action: 'read'
    },
  },
  {
    path: '/discountcodes/create',
    name: 'discountcode-create',
    component: () => import('@/views/discountcode/DiscountCodeCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'Administration',
      action: 'create'
    },
  },
  {
    path: '/discountcodes/:id',
    name: 'discountcode-view',
    component: () => import('@/views/discountcode/DiscountCodeView.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Administration',
      action: 'read'
    },
  },
  {
    path: '/discountcodes/edit/:id',
    name: 'discountcode-edit',
    component: () => import('@/views/discountcode/DiscountCodeEdit.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Administration',
      action: 'update'
    },
  },
]

export default discountcodes
