/* Country Vue Router Routes */
const countries = [
  {
    path: '/countries',
    name: 'country-list',
    component: () => import('@/views/country/CountryList.vue'),
    meta: {
      layout: 'content',
      resource: 'Reference',
      action: 'read'
    },
  },
  {
    path: '/countries/create',
    name: 'country-create',
    component: () => import('@/views/country/CountryCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'Reference',
      action: 'create'
    },
  },
  {
    path: '/countries/:id',
    name: 'country-view',
    component: () => import('@/views/country/CountryView.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Reference',
      action: 'read'
    },
  },
  {
    path: '/countries/edit/:id',
    name: 'country-edit',
    component: () => import('@/views/country/CountryEdit.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Reference',
      action: 'update'
    },
  },
]

export default countries
