/* OrderStatus Vue Router Routes */
const orderstatuses = [
  {
    path: '/orderstatuses',
    name: 'orderstatus-list',
    component: () => import('@/views/orderstatus/OrderStatusList.vue'),
    meta: {
      layout: 'content',
      resource: 'Reference',
      action: 'read'
    },
  },
  {
    path: '/orderstatuses/:id',
    name: 'orderstatus-view',
    component: () => import('@/views/orderstatus/OrderStatusView.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Reference',
      action: 'read'
    },
  }
]

export default orderstatuses
