/* ProductCategory Vue Router Routes */
const productcategories = [
  {
    path: '/productcategories',
    name: 'productcategory-list',
    component: () => import('@/views/productcategory/ProductCategoryList.vue'),
    meta: {
      layout: 'content',
      resource: 'Reference',
      action: 'read'
    },
  },
  {
    path: '/productcategories/create',
    name: 'productcategory-create',
    component: () => import('@/views/productcategory/ProductCategoryCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'Reference',
      action: 'create'
    },
  },
  {
    path: '/productcategories/:id',
    name: 'productcategory-view',
    component: () => import('@/views/productcategory/ProductCategoryView.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Reference',
      action: 'read'
    },
  },
  {
    path: '/productcategories/edit/:id',
    name: 'productcategory-edit',
    component: () => import('@/views/productcategory/ProductCategoryEdit.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Reference',
      action: 'update'
    },
  },
]

export default productcategories
