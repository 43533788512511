/* Member Vuex State Management
import { stateMember, gettersMember, mutationsMember actionsMember} from './member'
*/
import axios from '@axios';

const stateMember = {
  Members: [],
  MembersTotal: 0,
  Member: {}
}

const gettersMember = {
};

const mutationsMember = {
  MEMBER_LIST_SET(state, value) {
    state.Members = value.items;
    state.MembersTotal = value.total;
  },
  MEMBER_ITEM_SET(state, value) {
    state.Member = value;
  }
};

const actionsMember = {
  // fetchMembersLookup(ctx, params) {

  //   return new Promise((resolve, reject) => {

  //     if (params.id) {
  //       axios
  //         .get(`${ctx.rootState.apiUrl}/api/members/${params.id}`)
  //         .then(response => {
  //           resolve(response.data)
  //         })
  //         .catch(error => reject(error));
  //     } else {
  //       axios
  //         .post(`${ctx.rootState.apiUrl}/api/members`, params)
  //         .then(response => {
  //           resolve(response.data.items);
  //         })
  //         .catch(error => reject(error));
  //     }
  //   });
  // },
  fetchMembers(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ctx.rootState.apiUrl}/api/members`, queryParams)
        .then(response => {
          const { items, total } = response.data;
          ctx.commit('MEMBER_LIST_SET', {
            items: items,
            total: total
          });
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  fetchMember(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ctx.rootState.apiUrl}/api/members/${id}`)
        .then(response => {
          ctx.commit('MEMBER_ITEM_SET', response.data);
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  updateMember(ctx, { id, entity }) {
    ctx.commit('MEMBER_ITEM_SET', entity);  // update immediately
    return new Promise((resolve, reject) => {
      axios
        .put(`${ctx.rootState.apiUrl}/api/members/${id}`, entity)
        .then(response => {
          ctx.commit('MEMBER_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
    })
  },
  createMember(ctx, { entity }) {

    ctx.commit('MEMBER_ITEM_SET', entity);  // update immediately
    return new Promise((resolve, reject) => {
      axios
        .post(`${ctx.rootState.apiUrl}/api/members/create`, entity)
        .then(response => {
          ctx.commit('MEMBER_ITEM_SET', response.data); // allow for server-side data
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
    })
  },
  fetchMembersLookup(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      if (queryParams.id) {
        axios
          .get(`${ctx.rootState.apiUrl}/api/members/${queryParams.id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error));
      } else {
        axios
          .post(`${ctx.rootState.apiUrl}/api/members`, queryParams)
          .then(response => {
            resolve(response.data.items);
          })
          .catch(error => reject(error));
      }
    });
  },
  ToggleEnabledMember(ctx, { id, enabled, comment }) {

    return new Promise((resolve, reject) => {
      axios
        .put(`${ctx.rootState.apiUrl}/api/members/${id}/toggleenabled`, {
          enabled,
          comment
        })
        .then(response => {
          ctx.commit('MEMBER_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: "Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
    })
  },

  SendEmailValidation(ctx, { id }) {
    console.log("SendEmailValidation 1");
    return new Promise((resolve, reject) => {
      axios
        .put(`${ctx.rootState.apiUrl}/api/members/${id}/sendemailvalidation`, {})
        .then(response => {
          //ctx.commit('MEMBER_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: "Validation Email Sent." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
    })
  },

}

export {
  stateMember,
  gettersMember,
  mutationsMember,
  actionsMember
}
