/* Manifest Vue Router Routes */
const manifests = [
  {
    path: '/manifests',
    name: 'manifest-list',
    component: () => import('@/views/manifest/ManifestList.vue'),
    meta: {
      layout: 'content',
      resource: 'Manifests'
    },
  },
  {
    path: '/manifests/create',
    name: 'manifest-create',
    component: () => import('@/views/manifest/ManifestCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'Manifests'
    },
  },
  {
    path: '/manifests/:id',
    name: 'manifest-view',
    component: () => import('@/views/manifest/ManifestView.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Manifests'
    },
  },
  {
    path: '/manifests/edit/:id',
    name: 'manifest-edit',
    component: () => import('@/views/manifest/ManifestEdit.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Manifests'
    },
  },
]

export default manifests
