const pages = [
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/me',
    name: 'page-account-settings',
    component: () => import('@/views/pages/account/AccountSettings.vue'),
    meta: {
      layout: 'content',
      resource: 'Public'
    }
  }
]

export default pages
