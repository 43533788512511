/* Member Vue Router Routes */
const members = [
  {
    path: '/book-in/find-member',
    name: 'member-findforbooking',
    component: () => import('@/views/member/FindForBooking.vue'),
    meta: {
      layout: 'content',
      resource: 'Members',
      action: 'read'
    },
  },
  {
    path: '/members',
    name: 'member-list',
    component: () => import('@/views/member/MemberList.vue'),
    meta: {
      layout: 'content',
      resource: 'Members',
      action: 'read'
    },
  },
  {
    path: '/members/create',
    name: 'member-create',
    component: () => import('@/views/member/MemberCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'Members',
      action: 'create'
    },
  },
  {
    path: '/members/:id',
    name: 'member-view',
    component: () => import('@/views/member/MemberView.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Members',
      action: 'read'
    },
  },
  {
    path: '/members/edit/:id',
    name: 'member-edit',
    component: () => import('@/views/member/MemberEdit.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Members',
      action: 'update'
    },
  },
]

export default members
